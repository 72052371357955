@import "../../variables";

@media screen and (min-width: 768px) {
    #carousel-container {
        height: 100dvh;
        width: 100vw;
        background-color: $primary;
        transition: 0.1s;
        background-repeat: no-repeat;
        position: relative;
        video{
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
        }
        &.carousel-image-0 {
            background-image: url("/images/carousel-background.webp") !important;
            background-size: 60vw;
            background-position-y: center;
        }
        &.carousel-image-1 {
            background-size: cover;
            background-position-y: center;
        }
        #carousel-sub-container {
            &.image{
                height: 100%;
                #carousel-text-container {
                    color: $third;
                    padding: 0 3vw;
                    #carousel-text-center-container {
                        padding-top: 42vh;
                        #carousel-text-title {
                            margin: 0;
                            padding: 0 0 3vh 0;
                            text-shadow: 2px 2px 5px rgba(7, 23, 51, 0.3);
                        }
                        #carousel-text-subtitle {
                            margin: 0 5%;
                            padding: 0 0 3vh 0;
                            text-shadow: 2px 2px 5px rgba(7, 23, 51, 0.3);
                        }
                        #carousel-text-action {
                            background-color: #fff;
                            border: none;
                            box-shadow: 0 3px 6px #00000029;
                            border-radius: 32px;
                            font-weight: bold;
                            padding: 8px 0;
                            width: 40% !important;
                            font-size: 1.2em;
                            text-decoration: none;
                            margin: 0 auto;
                            display: block;
                        }
                    }
                }
                #carousel-image-container {
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    transition: 0.1s;
                    &.carousel-image-0 {
                        display: block;
                    }
                    &.carousel-image-1 {
                        display: none;
                    }
                    
                }
            }
            &.video{
                height: 100%;
                #carousel-text-container {
                    position: absolute;
                }
                #carousel-image-container {
                    display: none;
                    
                }
                #carousel-video-container{
                    width: 100%;
                    height: auto;
                    padding: 0;
                    margin: 0;
                    object-fit: contain;
                }
            }
            
        }
        #carousel-list {
            margin: 0 0 0 0;
            padding: 0;
            display: block;
            width: 100%;
            text-align: center;
            position: absolute;
            bottom: 3%;
            left: 0;
            .carousel-list-item {
                margin: 0 3px;
                padding: 0;
                display: inline-block;
                vertical-align: middle;
                width: 13px;
                height: 13px;
                border: 1px solid #fff;
                border-radius: 50%;
                cursor: pointer;
                transition: all 0.1s ease-in-out;
                &.active,
                &:hover {
                    background-color: #fff;
                }
            }
        }
    }
    #related-products-container{
        text-align: center;
        #related-title{
            margin: 0;
            padding: 0 0 2vh 0;
            display: block;
            text-align: center;
            color: $secondary;
            font-size: 1.8rem;

        }
        #related-products-list{
            list-style: none;
            overflow-x: auto;
            transition: .3s;
            vertical-align: middle;
            white-space: nowrap;
            width: 100%;
            padding: 0 0 4vh 0!important;
            text-align: left;
            display: flex;
            justify-content: center;
            &::-webkit-scrollbar {
                height: 4px;
            }
            .product-list-item{
                margin: 0 1vw;
                display: inline-grid;
                .product-list-item-categories-container{
                    width: 100%;
                    overflow: hidden;
                    .product-list-item-category{
                        
                    }
                }
                
            }
        }
        #related-products-action{
            margin: 0 auto;
            display: block;
            padding: 10px 20px;
            background-color: $secondary;
            width: fit-content;
            cursor: pointer;
            color: $third;
            border-radius: 32px;
            font-size: 1.2em;
            text-decoration: none;
        }
    }
    #brands-container {
        background-color: #f4f4f4;
        padding-bottom: 8vh;
        min-height: 85vh;
        #brand-title {
            color: $secondary;
            margin: 0;
            padding: 7vh 0;
        }
        #brands-list {
            list-style: none;
            .brands-list-item {
                background: #ffffff 0% 0% no-repeat padding-box;
                border-radius: 40px;
                width: 24%;
                padding: 5vh 2vh;
                .brand-img {
                    width: 85%;
                    box-shadow: 0px 3px 6px #00000029;
                    border-radius: 50%;
                    margin: 0 auto;
                    padding: 0;
                }
                .brand-title,
                .brand-paragraph {
                    color: $primary;
                }
                .brand-title {
                    margin: 3vh 0 2vh;
                    padding: 0;
                }
                .brand-paragraph {
                    margin: 0 0 2vh 0;
                    padding: 0;
                    line-height: 15px;
                    font-size: 0.9em;
                    display: block; /* Fallback for non-webkit */
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .brand-button {
                    padding: 7px 0;
                    border: none;
                    margin: 0 auto;
                    width: 80%;
                    background-color: $secondary;
                    color: $third;
                    border-radius: 32px;
                }
            }
        }
    }
    #category-container {
        background-image: url("/images/home/background-category.svg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        min-height: 70vh;
        width: 100vw;
        padding-bottom: 6vh;
        #category-title {
            color: $third;
            padding: 7vh 0 4vh;
            margin: 0;
        }
        #category-list {
            list-style: none;
            margin-bottom: 0;
            width: 100%;
            padding: 0 22vw;
            .category-list-item {
                background: #ffffff 0% 0% no-repeat padding-box;
                border-radius: 40px;
                padding: 2% 2%;
                margin: 2vh 1vh;
                width: 23%;
                .category-img {
                    height: 115px;
                }
                .category-title,
                .category-paragraph {
                    color: $primary;
                }
                .category-title {
                    margin: 1vh 0;
                    padding: 0;
                    font-size: 0.8rem;
                }
                .category-paragraph {
                    margin: 0 0 6vh;
                    padding: 0;
                }
                .category-button {
                    padding: 7px 25px;
                    border: none;
                    margin: 0 auto;
                    background-color: $secondary;
                    color: $third;
                    border-radius: 32px;
                }
            }
        }
    }
    #galery-container {
        width: 100%;
        margin: 0;
        #galery-title {
            color: $secondary;
            padding: 5vh 0 0;
            margin: 0;
        }
        .galery-arrow {
            font-size: 1.5em;
        }
        #galery-carousel-list {
            margin: 0;
            padding: 0;
            list-style: none;
            grid-row: 1 / 2;
            grid-column: 1 / 8;
            width: 90%;
            min-height: 62vh;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            transform-style: preserve-3d;
            perspective: 600px;
            pointer-events: none;
            .galery-carousel-list-item {
                background-repeat: no-repeat;
                background-size: cover;
                background-position-y: center;
                width: 45vw;
                height: 30vw;
                position: absolute;
                --r: calc(var(--position) - var(--offset));
                --abs: max(calc(var(--r) * -1), var(--r));
                transition: 0.1s;
                transform: rotateY(calc(-10deg * var(--r)))
                    translateX(calc(-300px * var(--r)));
                z-index: calc((var(--position) - var(--abs)));
            }
            .galery-carousel-list-item-0 {
                transform: translate(-35%, 0) scale(0.8);
                z-index: 6;
                opacity: 0.2;
            }
            .galery-carousel-list-item-1 {
                transform: translate(-20%, 0) scale(0.9);
                z-index: 8;
                opacity: 0.2;
            }
            .galery-carousel-list-item-2 {
                transform: translate(0, 0) scale(1);
                z-index: 10;
            }
            .galery-carousel-list-item-3 {
                transform: translate(20%, 0) scale(0.9);
                z-index: 9;
                opacity: 0.05;
            }
            .galery-carousel-list-item-4 {
                transform: translate(35%, 0) scale(0.8);
                z-index: 7;
                opacity: 0.2;
            }
        }
        #company-description {
            text-align: center;
            margin: 0 auto;
            display: block;
            width: 90%;
            font-size: 0.95em;
            padding-top: 2%;
            padding-bottom: 5vh;
        }
    }
    #about-us-container {
        height: 90vh;
        background-color: #f4f4f4;
        padding: 0 12vw;
        #about-us-text-container {
            padding: 0;
            #about-us-text-title,
            #about-us-text-paragraph {
                margin: 0;
                padding: 0 0 3vh 0;
            }
            #about-us-text-title {
                color: $secondary;
            }
            #about-us-text-paragraph {
                font-size: 0.95em;
            }
            #about-us-text-action {
                background-color: $secondary;
                color: #f4f4f4;
                border: 1px solid $secondary;
                border-radius: 32px;
                font-weight: bold;
                padding: 10px 0;
                width: 70%;
                font-size: 1.2em;
                text-decoration: none;
                display: block;
                margin: 0 auto;
            }
        }
        #about-us-info-container {
            #about-us-list {
                list-style: none;
                margin: 0;
                width: 100%;
                justify-content: space-between;
                .about-us-list-item {
                    background: #ffffff 0% 0% no-repeat padding-box;
                    box-shadow: 0px 3px 6px #00000029;
                    border-radius: 33px;
                    padding: 4vh 2%;
                    margin: 1vh 0;
                    width: 49%;
                    height: 38vh;
                    cursor: pointer;
                    transition: 0.3s;
                    .about-us-img {
                        width: 140px;
                        margin: auto;
                    }
                    #img-vision {
                        padding-top: 30px;
                        padding-bottom: 28px;
                    }
                    .about-us-title {
                        color: $secondary;
                        text-align: center;
                        width: 100%;
                    }
                    .about-us-title {
                        margin: 3vh 0;
                        padding: 0;
                    }
                    .about-us-paragraph {
                        color: $primary;
                        text-align: center;
                        margin: 0 0 6vh;
                        padding: 0;
                        width: 100%;
                        display: none;
                        font-size: 12px;
                        max-height: 100%;
                    }
                    .about-us-button {
                        padding: 7px 40px;
                        border: none;
                        margin: 0 auto;
                        background-color: $third;
                        color: $secondary;
                        border-radius: 32px;
                    }
                }
            }
        }
    }
    #clients-container {
        margin: 0;
        padding: 4vh 0;
        display: block;
        width: 100%;
        #clients-title {
            color: $secondary;
            margin: 0;
            padding: 1vh 0 2vh 0;
        }
        #clients-sub-title {
            color: #000;
            margin: 10px auto;
            padding: 0;
            width: 50%;
            min-width: 500px;
            font-size: 0.95em;
        }
        #clients-images-container {
            margin: 2vh auto 0 auto;
            height: 40vw;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            .clients-images {
                border-width: 5px;
                border-style: solid;
                border-radius: 50%;
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: 0.5s;
                transform: translate(-50%, 0%);
                -webkit-box-shadow: -5px 5px 18px 0px rgba(0, 0, 0, 0.3);
                -moz-box-shadow: -5px 5px 18px 0px rgba(0, 0, 0, 0.3);
                box-shadow: -5px 5px 18px 0px rgba(0, 0, 0, 0.3);
                cursor: pointer;
                &:hover {
                    transform: translate(-49%, 0%);
                }
            }
            #clients-images-1 {
                border-color: $yellow;
                width: 5.5vw;
                height: 5.5vw;
                top: 3.5vw;
                left: 42.5%;
            }
            #clients-images-2 {
                border-color: $yellow;
                width: 7.5vw;
                height: 7.5vw;
                top: 0vw;
                left: 50%;
            }
            #clients-images-3 {
                border-color: $yellow;
                width: 6vw;
                height: 6vw;
                top: 3vw;
                left: 58%;
            }
            #clients-images-4 {
                border-color: $blue;
                width: 7.5vw;
                height: 7.5vw;
                top: 8.5vw;
                left: 63%;
            }
            #clients-images-5 {
                border-color: $yellow;
                width: 7.4vw;
                height: 7.4vw;
                top: 9vw;
                left: 39%;
            }
            #clients-images-6 {
                border-color: $yellow;
                width: 9.2vw;
                height: 9.2vw;
                top: 9vw;
                left: 48%;
            }
            #clients-images-7 {
                border-color: $yellow;
                width: 5vw;
                height: 5vw;
                top: 10vw;
                left: 56%;
            }
            #clients-images-8 {
                border-color: $blue;
                width: 5.5vw;
                height: 5.5vw;
                top: 16vw;
                left: 56.5%;
            }
            #clients-images-9 {
                border-color: $red;
                width: 6.9vw;
                height: 6.9vw;
                top: 17vw;
                left: 63.5%;
            }
            #clients-images-10 {
                border-color: $yellow;
                width: 8.55vw;
                height: 8.55vw;
                top: 17vw;
                left: 39.5%;
            }
            #clients-images-11 {
                border-color: $blue;
                width: 9vw;
                height: 9vw;
                top: 19.5vw;
                left: 50%;
            }
            #clients-images-12 {
                border-color: $red;
                width: 8vw;
                height: 8vw;
                top: 24.5vw;
                left: 60%;
            }
            #clients-images-13 {
                border-color: $yellow;
                width: 5.5vw;
                height: 5.5vw;
                top: 26vw;
                left: 42%;
            }
            #clients-images-14 {
                border-color: $blue;
                width: 5.5vw;
                height: 5.5vw;
                top: 29.5vw;
                left: 47%;
            }
            #clients-images-15 {
                border-color: $red;
                width: 5vw;
                height: 5vw;
                top: 30vw;
                left: 53.5%;
            }
        }
    }
}
@media screen and (max-width: 768px) {
    #carousel-container {
        height: 60vh;
        width: 100vw;
        background-color: $primary;
        transition: 0.1s;
        background-repeat: no-repeat;
        background-position: 94% 50%;
        background-size: cover;
        video{
            width: 100%;
            height: 60vh;
            object-fit: cover;
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
        }
        #carousel-sub-container {
            &.image{
                height: 100%;
                #carousel-text-container {
                    color: $third;
                    padding: 0 3vw;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    #carousel-text-center-container {
                        #carousel-text-title {
                            margin: 0;
                            padding: 15vh 0 2vh 0;
                            font-size: 20px;
                            text-shadow: 2px 2px 5px rgba(7, 23, 51, 0.7);
                        }
                        #carousel-text-subtitle {
                            display: none !important;
                        }
                        #carousel-text-action {
                            background-color: #fff;
                            border: none;
                            box-shadow: 0 3px 6px #00000029;
                            border-radius: 32px;
                            font-weight: bold;
                            padding: 8px 8px;
                            font-size: 16px;
                            text-decoration: none;
                            margin: 0 auto;
                            display: block;
                            width: 65%;
                        }
                    }
                }
                #carousel-image-container {
                    display: none;
                }
            }
            &.video{
                height: 100%;
                #carousel-text-container {
                    position: absolute;
                }
                #carousel-image-container {
                    display: none;
                    
                }
                #carousel-video-container{
                    width: 100%;
                    height: auto;
                    padding: 0;
                    margin: 0;
                    object-fit: contain;
                }
            }
        }
        #carousel-list {
            margin: 0 0 0 0;
            padding: 0;
            display: block;
            width: 100%;
            text-align: center;
            position: absolute;
            top: 55vh;
            left: 0;
            .carousel-list-item {
                margin: 0 3px;
                padding: 0;
                display: inline-block;
                vertical-align: middle;
                width: 13px;
                height: 13px;
                border: 1px solid #fff;
                border-radius: 50%;
                cursor: pointer;
                transition: all 0.1s ease-in-out;
                &.active,
                &:hover {
                    background-color: #fff;
                }
            }
        }
    }
    #related-products-container{
        padding: 5vh 0;
        #related-products-action{
            margin: 0 auto;
            display: block;
            padding: 10px 20px;
            background-color: $secondary;
            width: fit-content;
            cursor: pointer;
            color: $third;
            border-radius: 32px;
            font-size: 1.2em;
            text-decoration: none;
        }
    }
    #brands-container {
        background-color: #f4f4f4;
        padding-bottom: 8vh;
        #brand-title {
            color: $secondary;
            margin: 0;
            padding: 4vh 0;
            font-size: 1.3rem;
        }
        #brands-list {
            list-style: none;
            flex-direction: column;
            margin: 0;
            padding: 0;
            width: 100%;
            .brands-list-item {
                background: #ffffff 0% 0% no-repeat padding-box;
                border-radius: 40px;
                width: 95%;
                margin: 0 auto 2vh auto;
                padding: 5vh 2vh;
                .brand-img {
                    width: 85%;
                    box-shadow: 0px 3px 6px #00000029;
                    border-radius: 50%;
                    margin: 0 auto;
                    padding: 0;
                }
                .brand-title,
                .brand-paragraph {
                    color: $primary;
                }
                .brand-title {
                    margin: 3vh 0 2vh;
                    padding: 0;
                    font-size: 1.3rem;
                }
                .brand-paragraph {
                    margin: 0 0 2vh;
                    padding: 0;
                    font-size: 0.9em;
                    display: block; /* Fallback for non-webkit */
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .brand-button {
                    padding: 5px 0;
                    border: none;
                    margin: 0 auto;
                    width: 80%;
                    background-color: $secondary;
                    color: $third;
                    border-radius: 32px;
                    font-size: 0.9em;
                }
            }
        }
    }
    #category-container {
        background-image: url("/images/home/background-category.svg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        min-height: 70vh;
        width: 100vw;
        padding-bottom: 6vh;
        #category-title {
            color: $third;
            padding: 5vh 0 5vh;
            margin: 0;
            font-size: 1.3rem;
        }
        #category-list {
            list-style: none;
            // flex-direction: column;
            margin: 0;
            padding: 0;
            width: 100%;
            .category-list-item {
                background: #ffffff 0% 0% no-repeat padding-box;
                border-radius: 30px;
                width: 40%;
                margin: 0 1.5vh 3vh 1.5vh;
                padding: 3% 3%;
                display: inline-flex;
                flex-direction: column;
                justify-content: space-between;
                text-align: center;
                .category-img {
                    height: 75px;
                    object-fit: contain;
                }
                .category-title,
                .category-paragraph {
                    color: $primary;
                }
                .category-title {
                    margin: 1vh 0;
                    padding: 0;
                    font-size: 0.8em;
                }
                .category-paragraph {
                    margin: 0 0 6vh;
                    padding: 0;
                }
                .category-button {
                    padding: 4px 20px;
                    border: none;
                    margin: 0 auto;
                    background-color: $secondary;
                    color: $third;
                    border-radius: 32px;
                    font-size: 0.9em;
                }
            }
        }
    }
    #galery-container {
        width: 100%;
        padding: 5vh 3%;
        margin: 0;
        #galery-title {
            color: $secondary;
            padding: 0 0 5vh 0;
            margin: 0;
            font-size: 1.3rem;
        }
        #galery-carousel-container {
            .galery-arrow {
                font-size: 1.3em;
                color: $secondary;
            }
            #galery-carousel-list {
                margin: 0;
                padding: 0;
                list-style: none;
                grid-row: 1 / 2;
                grid-column: 1 / 8;
                width: 90%;
                min-height: 30vh;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                transform-style: preserve-3d;
                perspective: 600px;
                pointer-events: none;
                .galery-carousel-list-item {
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position-y: center;
                    width: 50vw;
                    height: 30vw;
                    position: absolute;
                    --r: calc(var(--position) - var(--offset));
                    --abs: max(calc(var(--r) * -1), var(--r));
                    transition: 0.1s;
                    transform: rotateY(calc(-10deg * var(--r)))
                        translateX(calc(-300px * var(--r)));
                    z-index: calc((var(--position) - var(--abs)));
                }
                .galery-carousel-list-item-0 {
                    transform: translate(-35%, 0) scale(0.8);
                    z-index: 6;
                }
                .galery-carousel-list-item-1 {
                    transform: translate(-20%, 0) scale(0.9);
                    z-index: 8;
                }
                .galery-carousel-list-item-2 {
                    transform: translate(0, 0) scale(2);
                    z-index: 10;
                }
                .galery-carousel-list-item-3 {
                    transform: translate(20%, 0) scale(0.9);
                    z-index: 9;
                }
                .galery-carousel-list-item-4 {
                    transform: translate(35%, 0) scale(0.8);
                    z-index: 7;
                }
            }
        }
        #company-description {
            text-align: center;
            margin: 0 auto;
            display: block;
            width: 90%;
            font-size: 0.9em;
            padding-top: 6%;
            padding-bottom: 0;
        }
    }
    #about-us-container {
        background-color: #f4f4f4;
        padding: 5vh 0;
        margin: 0;
        width: 100%;
        #about-us-text-center-container {
            padding: 0 6vh;
        }
        #about-us-text-container {
            padding: 0;
            #about-us-text-title,
            #about-us-text-paragraph {
                margin: 0;
                padding: 0 0 3vh 0;
            }
            #about-us-text-title {
                color: $secondary;
                font-size: 1.3rem;
            }
            #about-us-text-paragraph {
                font-size: 0.9em;
            }
            #about-us-text-action {
                background-color: $secondary;
                color: #f4f4f4;
                border: 1px solid $secondary;
                border-radius: 32px;
                font-weight: bold;
                padding: 5px 0;
                width: 70%;
                font-size: 1em;
                text-decoration: none;
                display: block;
                margin: 0 auto;
            }
        }
        #about-us-info-container {
            #about-us-list {
                list-style: none;
                margin: 3vh 0 0 0;
                padding: 0;
                width: 100%;
                justify-content: center;
                .about-us-list-item {
                    background: #ffffff 0% 0% no-repeat padding-box;
                    box-shadow: 0px 3px 6px #00000029;
                    border-radius: 33px;
                    padding: 4vh 2%;
                    margin: 1vh 1vh;
                    width: 85%;
                    height: 30vh;
                    cursor: pointer;
                    transition: 0.3s;
                    .about-us-img {
                        width: 80px;
                        margin: auto;
                    }
                    #img-vision {
                        padding-top: 20px;
                        padding-bottom: 20px;
                    }
                    .about-us-title {
                        color: $secondary;
                        text-align: center;
                        width: 100%;
                    }
                    .about-us-paragraph {
                        color: $primary;
                        text-align: center;
                        width: 100%;
                    }
                    .about-us-title {
                        margin: 1vh 0;
                        padding: 0;
                    }
                    .about-us-paragraph {
                        margin: 0 0 6vh;
                        padding: 0;
                        max-height: 100%;
                        display: none;
                        font-size: 12px;
                    }
                    .about-us-button {
                        padding: 7px 40px;
                        border: none;
                        margin: 0 auto;
                        background-color: $third;
                        color: $secondary;
                        border-radius: 32px;
                    }
                }
            }
        }
    }
    #clients-container {
        margin: 0 auto;
        padding: 6vh 0;
        display: block;
        width: 95%;
        #clients-title {
            color: $secondary;
            margin: 0;
            padding: 0 6vh;
            font-size: 1.3rem;
        }
        #clients-sub-title {
            color: #000;
            margin: 10px auto;
            padding: 0 2vh;
            width: 100%;
            font-size: 0.9em;
        }
        #clients-images-container {
            margin: 5vh auto 0 auto;
            height: 80vw;
            width: 105%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            .clients-images {
                border-width: 3px;
                border-style: solid;
                border-radius: 50%;
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: 0.5s;
                transform: translate(-50%, 0%);
                -webkit-box-shadow: -5px 5px 18px 0px rgba(0, 0, 0, 0.15);
                -moz-box-shadow: -5px 5px 18px 0px rgba(0, 0, 0, 0.15);
                box-shadow: -5px 5px 18px 0px rgba(0, 0, 0, 0.15);
                cursor: pointer;
                &:hover {
                    transform: translate(-49%, 0%);
                }
            }
            #clients-images-1 {
                border-color: $yellow;
                width: 12.5vw;
                height: 12.5vw;
                top: 5.5vw;
                left: 30.5%;
            }
            #clients-images-2 {
                border-color: $yellow;
                width: 14.5vw;
                height: 14.5vw;
                top: 0vw;
                left: 47%;
            }
            #clients-images-3 {
                border-color: $yellow;
                width: 13vw;
                height: 13vw;
                top: 5vw;
                left: 64.5%;
            }
            #clients-images-4 {
                border-color: $blue;
                width: 14.5vw;
                height: 14.5vw;
                top: 16.5vw;
                left: 75%;
            }
            #clients-images-5 {
                border-color: $yellow;
                width: 14.4vw;
                height: 14.4vw;
                top: 18vw;
                left: 20%;
            }
            #clients-images-6 {
                border-color: $yellow;
                width: 19.2vw;
                height: 19.2vw;
                top: 18vw;
                left: 39.5%;
            }
            #clients-images-7 {
                border-color: $yellow;
                width: 13vw;
                height: 13vw;
                top: 20vw;
                left: 58%;
            }
            #clients-images-8 {
                border-color: $blue;
                width: 12.5vw;
                height: 12.5vw;
                top: 35vw;
                left: 61%;
            }
            #clients-images-9 {
                border-color: $red;
                width: 13.9vw;
                height: 13.9vw;
                top: 35vw;
                left: 77%;
            }
            #clients-images-10 {
                border-color: $yellow;
                width: 15.55vw;
                height: 15.55vw;
                top: 36vw;
                left: 20%;
            }
            #clients-images-11 {
                border-color: $blue;
                width: 20.55vw;
                height: 20.55vw;
                top: 39.5vw;
                left: 44%;
            }
            #clients-images-12 {
                border-color: $red;
                width: 16.6vw;
                height: 16.6vw;
                top: 49.5vw;
                left: 69.5%;
            }
            #clients-images-13 {
                border-color: $yellow;
                width: 12.5vw;
                height: 12.5vw;
                top: 54vw;
                left: 27%;
            }
            #clients-images-14 {
                border-color: $blue;
                width: 12.5vw;
                height: 12.5vw;
                top: 63.5vw;
                left: 39%;
            }
            #clients-images-15 {
                border-color: $red;
                width: 13vw;
                height: 13vw;
                top: 62vw;
                left: 55.5%;
            }
        }
    }
}
